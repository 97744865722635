import React, { useState } from 'react';

function PasswordGate({ onCorrectPassword }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    
    const correctPassword = process.env.REACT_APP_SITE_PASSWORD; // You can change this or move it to env variables

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            localStorage.setItem('siteAccess', 'granted');
            onCorrectPassword();
        } else {
            setError(true);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-custom-bg">
            <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-bodoni">
                        The Moon Teller
                    </h2>
                    <p className="mt-2 text-center text-gray-600">
                        This site has not launched yet.
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(false);
                            }}
                            className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                                error ? 'border-red-300' : 'border-gray-300'
                            } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                            placeholder="Enter password"
                        />
                    </div>
                    {error && (
                        <p className="text-red-500 text-sm text-center">
                            Incorrect password
                        </p>
                    )}
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Enter Site
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PasswordGate;