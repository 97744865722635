import { useState } from 'react';

export const useSignUp = (newsletterId, endpoint = 'https://signup.wmg.com/register') => {
    const [status, setStatus] = useState('idle');

    const register = (email, additionalParams = {}) => {
        const listIds = Array.isArray(newsletterId) ? newsletterId.join(',') : newsletterId.toString();
        const query = new URLSearchParams({
            newsletterId: listIds,
            email,
            ...additionalParams,
        }).toString();
        const resource = `${endpoint}?${query}&jsonp=mlcallback`;

        setStatus('loading');

        return fetch(resource, { method: 'POST' })
            .then(async (response) => {
                const success = response.ok;
                const responseData = await response.text();
                console.log('Server response:', responseData);
                setStatus(success ? 'ready' : 'error');
                return success;
            })
            .catch((error) => {
                console.error('Error:', error);
                setStatus('error');
                return false;
            });
    };

    return { status, register };
};