import React, { useEffect, useState } from 'react';

function Footer() {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Function to check consent status
        const checkConsentStatus = () => {
            if (window.OneTrust && typeof window.OneTrust.GetDomainData === 'function') {
                const domainData = window.OneTrust.GetDomainData();
                const consentedGroups = domainData.Groups.filter(group => group.HasConsentOptOut && group.GroupName !== 'Strictly Necessary Cookies');
                const hasConsented = consentedGroups.some(group => group.HasConsentOptOut && group.IsConsented);
                setConsentGiven(hasConsented);
            }
        };

        // Function to show OneTrust settings
        const showOneTrustSettings = (retryCount = 0) => {
            console.log("Attempting to show OneTrust settings, attempt:", retryCount + 1);
            const settingsButton = document.querySelector('.ot-sdk-show-settings');
            if (settingsButton) {
                console.log("Found settings button, clicking");
                settingsButton.click();
            } else if (retryCount < 5) {
                console.log("Settings button not found, retrying in 5 seconds");
                setTimeout(() => showOneTrustSettings(retryCount + 1), 2500);
            } else {
                console.error("Failed to find settings button after 5 attempts");
            }
        };

        // Check consent status and show settings if necessary
        const initializeOneTrust = () => {
            console.log("Initializing OneTrust");
            checkConsentStatus();
            console.log("Consent status:", consentGiven);
            if (!consentGiven) {
                showOneTrustSettings();
            }
        };

        // Set up an interval to check consent status periodically
        const intervalId = setInterval(checkConsentStatus, 1000);

        // Initial check and settings show
        initializeOneTrust();

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [consentGiven]);

    return (
        <footer className="w-full mt-4 text-center text-[#0a0a0a]">
            <img src="./Assets/Images/moons.png" alt="Moon Phases" className="pt-4 w-[200px] mx-auto" />
            <div className="text-xs my-4">
                © 2024 WARNER MUSIC PHILIPPINES
                <span className="mx-2">|</span>
                <a href="https://privacy.wmg.com/wmi/privacy" className="underline hover:text-gray-600">Privacy Policy</a>
                <span className="mx-2">|</span>
                <a href="https://privacy.wmg.com/wmi/terms-of-use" className="underline hover:text-gray-600">Terms of Use</a>
                <span className="mx-2">|</span>
                <a href="https://www.wminewmedia.com/cookies-policy/" target="_blank" className="underline hover:text-gray-600">Cookies Policy</a>
                <span className="mx-2">|</span>
                <a className="ot-sdk-show-settings underline hover:text-gray-600">Do Not Sell or Share My Personal Information</a>
            </div>
            <div id="onetrust-consent-sdk"></div>
        </footer>
    );
}

export default Footer;
